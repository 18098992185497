<template>
  <b-card class="prozess-todo">
    <h2 class="mb-4">{{ $t('To Do') }}</h2>
    <div
      v-if="loading"
      class="d-flex justify-content-center mb-2"
    >
      <b-spinner />
    </div>
    <form
      v-else
      id="formSidebar"
      @submit.prevent="save"
    >
      <div class="d-flex align-items-center mb-2">
        <h4 class="mr-3">{{ $t('To Do Group') }}</h4>
        <div class="prozess-todo__user-group">
          <user-group-search
            v-model="userGroup"
            :disabled="!isEditMode"
          />
        </div>
      </div>

      <div class="d-flex align-items-center mb-2">
        <h4 class="mr-3">{{ $t('Reference Number Start') }}</h4>
        <div class="prozess-todo__user-group">
          <prozess-input
            v-model="form.referenceNumberStart"
            class="mt-3"
            type="number"
            icon="PlusIcon"
            field="referenceNumberStart"
            name="referenceNumberStart"
            :disabled="!isEditMode"
            :placeholder="$t('Start of reference number')"
          />
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h4 style="margin-bottom: 0;">{{ $t('Show Universal Fields') }}</h4>
            </div>
            <b-form-checkbox
              :disabled="!isEditMode"
              :checked="showUniversalFields"
              class="toggle-with-disable-class"
              style="color: red"
              switch
              inline
              @change="toggleShowUniversalFields"
            >
            </b-form-checkbox>
          </div>
        </div>
        <p v-if="universalFieldError != null" style="color: red">{{ $t(this.universalFieldError) }}</p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          v-if="isEditMode"
          variant="outline-primary"
          class="mr-1"
          @click="cancel"
        >
          {{ $t('Cancel') }}
        </b-button>
        <button
          id="edit-btn"
          class="btn btn-primary mr-1 d-flex justify-content-center align-items-center"
          type="submit"
          form="formSidebar"
          :disabled="saving || !hasSystemSettingsActions"
        >
          <b-spinner
            v-if="saving"
            small
            class="mr-1"
          />
          <span>{{ $t(saveLabel) }}</span>
        </button>
      </div>
    </form>
  </b-card>
</template>

<script>
import UserGroupSearch from '@/views/pages/SystemSettings/Todo/UserGroupSearch.vue'
import { metastatusService } from '@/services/metastatus'
import settingsService from '@/services/settings'
import todoService from '@/services/todo'
import groupService from '@/services/group'

export default {
  components: {
    UserGroupSearch,
  },
  data() {
    return {
      loading: false,
      saving: false,
      isEditMode: false,
      showUniversalFields: true,
      originalShowUnivFields: true,
      userGroup: null,
      originalUserGroup: null,
      originalRefNumStart: null,
      form: {},
      metastatusService: null,
      universalFieldError: null,
    }
  },
  computed: {
    saveLabel() {
      return this.isEditMode ? 'Save' : 'Edit'
    },
  },
  created() {
    this.getInitialData()
    this.metastatusService = metastatusService('core')
  },
  methods: {
    async getInitialData() {
      this.loading = true
      const { response: settingsResponse } = await this.$async(settingsService.getOne('todo'))
      const userGroupId = settingsResponse?.data
      if (userGroupId) {
        const { response: groupResponse } = await this.$async(groupService.getOne(userGroupId))
        const { name, uuidKey } = groupResponse?.data
        if (uuidKey) {
          this.userGroup = {
            isNew: false,
            label: name,
            value: uuidKey,
          }
          this.originalUserGroup = this.$lodash.cloneDeep(this.userGroup)
        }
      }
      const { response: refIdSettingRes } = await this.$async(settingsService.getOne('REF_ID_START_TODO'))
      const refStart = refIdSettingRes?.data
      this.form = { referenceNumberStart: this.$lodash.cloneDeep(refStart) }
      this.originalRefNumStart = this.$lodash.cloneDeep(refStart)

      const { response: showUnivSettingRes } = await this.$async(settingsService.getOne('SHOW_UNIV_FIELDS_TODO'))
      const showUnivFields = showUnivSettingRes?.data
      this.originalShowUnivFields = this.$lodash.cloneDeep(showUnivFields)
      this.showUniversalFields = this.$lodash.cloneDeep(showUnivFields)

      this.loading = false
    },
    toggleShowUniversalFields() {
      this.showUniversalFields = !this.showUniversalFields
    },
    cancel(e) {
      e.preventDefault()
      this.universalFieldError = null
      this.isEditMode = false
      this.userGroup = this.originalUserGroup
      this.form = { referenceNumberStart: this.$lodash.cloneDeep(this.originalRefNumStart) }
      this.showUniversalFields = this.$lodash.cloneDeep(this.originalShowUnivFields)
    },
    async save() {
      if (!this.isEditMode) {
        this.isEditMode = true
        return
      }
      this.saving = true

      const defaultMetastatusRes = await this.metastatusService.getDefaults('todo')
      const defaultMetastatus = defaultMetastatusRes.data
      const standardHasDefaults = !Object.values(defaultMetastatus).some(el => el === null)

      if (this.showUniversalFields && !standardHasDefaults) {
        this.universalFieldError = 'Please set default values for standard fields'
        this.saving = false
        return
      }
      await this.$async(todoService.saveSettings(this.userGroup?.value || '', this.form.referenceNumberStart, this.showUniversalFields))

      this.originalUserGroup = this.$lodash.cloneDeep(this.userGroup)
      this.originalRefNumStart = this.$lodash.cloneDeep(this.form.referenceNumberStart)
      this.originalShowUnivFields = this.$lodash.cloneDeep(this.showUniversalFields)
      this.saving = false
      this.isEditMode = false
    },
  },
}
</script>

<style lang="scss">
.prozess-todo {
  &__user-group {
    width: 300px;
  }
}
</style>
